<template>
  <div class="page-content has-padding">
    <ui-page-hero title="Options" />

    <section class="section">
      <div class="container">
        <OptionList />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    OptionList: () => import('@/components/Options/OptionList'),
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  created() {},

  beforeDestroy() {
    this.clearOptionsPage()
  },

  methods: {
    ...mapActions('optionStore', ['clearOptionsPage']),
  },
}
</script>

<style></style>
